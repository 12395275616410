// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amsterdam-js": () => import("./../../../src/pages/amsterdam.js" /* webpackChunkName: "component---src-pages-amsterdam-js" */),
  "component---src-pages-athens-js": () => import("./../../../src/pages/athens.js" /* webpackChunkName: "component---src-pages-athens-js" */),
  "component---src-pages-barcelona-js": () => import("./../../../src/pages/barcelona.js" /* webpackChunkName: "component---src-pages-barcelona-js" */),
  "component---src-pages-cambodia-js": () => import("./../../../src/pages/cambodia.js" /* webpackChunkName: "component---src-pages-cambodia-js" */),
  "component---src-pages-empty-js": () => import("./../../../src/pages/empty.js" /* webpackChunkName: "component---src-pages-empty-js" */),
  "component---src-pages-florence-js": () => import("./../../../src/pages/florence.js" /* webpackChunkName: "component---src-pages-florence-js" */),
  "component---src-pages-gold-coast-js": () => import("./../../../src/pages/gold-coast.js" /* webpackChunkName: "component---src-pages-gold-coast-js" */),
  "component---src-pages-great-ocean-road-js": () => import("./../../../src/pages/great-ocean-road.js" /* webpackChunkName: "component---src-pages-great-ocean-road-js" */),
  "component---src-pages-hawea-js": () => import("./../../../src/pages/hawea.js" /* webpackChunkName: "component---src-pages-hawea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lisbon-js": () => import("./../../../src/pages/lisbon.js" /* webpackChunkName: "component---src-pages-lisbon-js" */),
  "component---src-pages-nelson-js": () => import("./../../../src/pages/nelson.js" /* webpackChunkName: "component---src-pages-nelson-js" */),
  "component---src-pages-oaxaca-js": () => import("./../../../src/pages/oaxaca.js" /* webpackChunkName: "component---src-pages-oaxaca-js" */),
  "component---src-pages-otago-js": () => import("./../../../src/pages/otago.js" /* webpackChunkName: "component---src-pages-otago-js" */),
  "component---src-pages-paris-js": () => import("./../../../src/pages/paris.js" /* webpackChunkName: "component---src-pages-paris-js" */),
  "component---src-pages-rome-js": () => import("./../../../src/pages/rome.js" /* webpackChunkName: "component---src-pages-rome-js" */),
  "component---src-pages-south-island-js": () => import("./../../../src/pages/south-island.js" /* webpackChunkName: "component---src-pages-south-island-js" */),
  "component---src-pages-sydney-js": () => import("./../../../src/pages/sydney.js" /* webpackChunkName: "component---src-pages-sydney-js" */),
  "component---src-pages-venice-js": () => import("./../../../src/pages/venice.js" /* webpackChunkName: "component---src-pages-venice-js" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

